/* Global Colors */

$conti-orange: #FFA500;
$conti-gray: #777;
$conti-light: #e6e6e6;
$conti-black: #000000;
$conti-white: #ffffff;

/* Bootstrap overwrite */
$warning: $conti-orange;
$primary: $conti-orange;
$primary-light: $conti-orange;
$light: $conti-white;
$danger: #d9534f;
$light-grey: $conti-light;
$dark-grey:  $conti-gray;
$dark: #555;
$success: #5cb85c;
$light-blue: #5bc0de;
$light-blue-hover: #31b0d5;
