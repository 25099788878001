
@import '../globals/colors';
@import "bootstrap/scss/bootstrap.scss";

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0px;
    opacity: 0.5;
    background-color: $light-grey;
 }

#spinner {
    visibility: hidden;
}

#spinner.busy {
    visibility: visible;
}

#form-stepper .nav-link {
    &:not(.active) {
        background-color: $primary;
        opacity: 0.65;
        color: $light;
    }

    &:not(.done) {
        background-color: $primary;
        color: $light;
    }

    &.disabled {
        background-color: $light-grey;
        color: $dark-grey;
    }
}

.select2-results__option--selected { background-color: $primary !important; }
.select2-results__option--highlighted {
    background-color: $primary-light !important;
    color: $light !important;
}

.modal-dialog {
    min-width: 1300px;
    justify-content: center;
}

.modal-content {
    max-width: fit-content;
    cursor: pointer;
  
    img {
      max-width: 100%;
      height: auto;
    }
  }

//jumbotron
.thumbnail {
    display: block;
    padding: 8px;
    margin-bottom: 20px;
    background-color: $light;
    border: 1px solid $light-grey;
    border-radius: 4px;
    transition: border .2s ease-in-out;

    h5 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.tooltip {
    padding-bottom: 0;
}

.tab-content button.btn-info.save {
    background-color: $light-blue;
    border: $light-blue;

    &:hover {
        background-color: $light-blue-hover;
        border: $light-blue-hover;
    }
}

.note-editor .custom-btn, .note-editor .btn {
    color: #111;
}

.custom-warning-btn {
    background-color: map-get($theme-colors, "warning");
}

.custom-dropdown-toggle {
    color: $dark-grey;
    text-decoration: none;
    padding: 0 15px;

    &:focus {
        color: $dark;
    }

    &::after {
        border: 0;
    }
}

.custom-labels {
        align-self: center;
        margin-bottom: 0;
}

.content {
    background-color: #eee;
    min-height: 100%;
    padding-top: 90px;
    padding-bottom: 40px;
    margin-left: 50px;
    width: calc(100% - 50px);
    position: relative;
}

#form-stepper {
    button.nav-link:not(.active) {
        background-color: #eee;
        color: #999;
        border-radius: 3px;
    }
}

.tab-content {
    background-color: #eee;
}
.tab-pane {
    padding: 20px;
    border-radius: 3px;
}

legend {
    margin-bottom: 20px;
    font-size: 15px;
    color: #333;
    border-bottom: 1px solid #e5e5e5;
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
    display: none;
}

.cover_image_upload {
    background-color: #eee;
    color: #999;
    border-radius: 3px;
}

.help-block {
    font-size: 12px;
    color: #999;
    margin-top: 5px;
    margin-bottom: 10px;
}

.custom-accordion {
    .accordion-collapse {
        overflow-x: scroll;
    }
    .accordion-button {
        &:not(.collapsed) {
            background-color: #eee;
            &::after {
            transition: none;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>');
            }
        }
        &::after {
            transition: none;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>');
        }
    }
}

.custom-table {
    .sort-link {
        text-decoration: none;
    }
}

.custom-btn {
    color: $light; 

    &.btn-default {
        color: $dark; 
        background-color: $light;
        border: 1px solid $dark;
        &:hover,
        &:active,
        &.active,
        &:focus {
            color:  $dark; 
            background-color: $light-grey;
            border-color: $dark-grey;
        }
    }

    &.btn-primary,
    &.btn-warning,
    &.btn-success,
    &.btn-danger,
    &.btn-info {
        &:hover,
        &:active,
        &:checked,
        &:active,
        &:first-child:active,
        &.show  {
            color:  $light !important;
        }
    }
}

.custom-navbar {
    margin-left: 50px;
    width: calc(100% - 50px);
}

.sidebar {
    position: fixed;
    z-index: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #242424;
    overflow-y: auto;

    .sidebar-header {
        margin: 10px 0 20px;
        height: 50px;
    }

    ul {
        list-style: none;
        padding: 0;
        li {
            border-top: 1px solid #2d2d2d;
            &:hover{  
                background-color: #111;
            }
            a {
                text-decoration: none;
                color: #aaa;
                padding: 10px 0px;
                display: block;
                .sidebar-icon {
                    width: 28px;
                    left: 11px;
                    height: 28px;
                    display: inline-block;
                    text-align: center;
                    position: relative;
                    font-weight: 100; 
                    line-height: 28px;
                }
                .sidebar-text {
                    margin-left: 16px;

                    @include media-breakpoint-up(sm) {
                        display: none;
                    }
                }
            }
        }
        li.active a {
            color: $primary;
            .sidebar-icon {
                border-radius: 50%;
                background-color: #fe9900;
                color: #242424
            }
        }
    }

    &.shown {
        width: 240px;

        .sidebar-text {
            display: inline-block;
        }

        + .navbar,
        ~ .content {
            margin-left: 240px;
            width: calc(100% - 240px);
          }
    } 
}

.widget .row + .row {
    margin-top: 8px;
}

.accordion {
    @extend .custom-accordion ;
}

.btn {
    @extend .custom-btn;
}

.btn-warning {
    @extend .custom-warning-btn;
}

.dropdown-toggle {
    @extend .custom-dropdown-toggle ;
}

.navbar {
    @extend .custom-navbar ;
}

.table {
    @extend .custom-table;
}

.labels {
    @extend .custom-labels;
}


.btn-file {
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    > input {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      opacity: 0;
      filter: alpha(opacity=0);
      font-size: 23px;
      height: 100%;
      width: 100%;
      direction: ltr;
      cursor: pointer;
    }
  }

  .note-btn.dropdown-toggle:after {
    content: none;
    }

.note-btn.dropdown-toggle {
    padding-top: 5px;
    padding-bottom: 5px;
}
